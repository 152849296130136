import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import actions from '../Agent/actions'
import {postRequest, getCustomRequest, getRequest, getPrivateRequest, deleteRequest, postCustomRequest, getRootReq, postRootReq, getBaseRequest, postBaseRequest} from '../../config/axiosClient'
import axios from 'axios';

import {topMiddleErrorAlert, topMiddleSuccessAlert} from '../Alert/apiSaga';
// import { filterTimeSlots, getEndSlot } from '../../config/helpers';

export const generic_error_message = 'Oops, something went wrong!';
export const getUser = (state) => state.authReducer.user;

function* loginAsUser(action) {
    const {email, id} = action.payload;
    try {
        // Make api requests.
        // postBaseRequest
        const res = yield call(() => postBaseRequest('loginAsUser', action.payload));
        // // axios.get("/sanctum/csrf-cookie");
        // const res = axios.post("/login", {
        //     email,
        //     password,
        // });

        // Authentication was successful.
        if (res.status === 204) {
            // dispatch(loadUser());
            // yield call(initUser)
            yield put({type: actions.AGENT_LOGIN_AS_USER_SUCCESS});
        }
    } catch (error) {
        if (error.response && error.response.status === 422) {
            const errorMessage = "Invalid credentials";
            yield put({type: actions.AGENT_LOGIN_AS_USER_FAILURE, payload: errorMessage});
            yield call(topMiddleErrorAlert, errorMessage);

        }
        else if (error.response && error.response.status === 419) {
            yield put({type: actions.AGENT_LOGIN_AS_USER_FAILURE, payload: 'Application access denied.'});
        } else {
            yield put({type: actions.AGENT_LOGIN_AS_USER_FAILURE, payload: generic_error_message});
        }
    }
};

function* actAsCustomerID(action) {

    try {
        yield put({type: actions.AGENT_ACT_AS_CUSTOMER_ID_SUCCESS, payload: action.payload});
    } catch (error) {
        yield put({type: actions.AGENT_ACT_AS_CUSTOMER_ID_FAILURE, payload: generic_error_message});
    }
};

function* getCountryList(action) {
    try {
      const response = yield call(() => getRequest('doris/countries')); 
      yield put({type: actions.GET_COUNTRY_LIST_SUCCESS, payload: response.data})
  
    } catch (error) {
      yield put({type: actions.GET_COUNTRY_LIST_FAILURE});
    }
}
function* getSectors(action) {
  try {
    const response = yield call(() => getBaseRequest('agent/get-sectors')); 
      yield put({type: actions.GET_SECTORS_SUCCESS, payload: response.data})
      
    } catch (error) {
      yield put({type: actions.GET_SECTORS_FAILURE});
    }
}

function* updateQuote(action) {
    try {
      const response = yield call(() => postBaseRequest('agent/update-quote-details', action.payload)); 
      yield put({type: actions.AGENT_UPDATE_QUOTE_DATA_SUCCESS, payload: response.data})
      
    } catch (error) {
      yield put({type: actions.AGENT_UPDATE_QUOTE_DATA_FAILURE});
      yield call(topMiddleErrorAlert, generic_error_message);
    }
  }
  
function* getScheduledCarriages(action) {
      try {
        const response = yield call(() => getBaseRequest('agent/get-scheduled-carriages' + action.payload.query)); 
        yield put({type: actions.GET_SCHEDULED_CARRIAGES_SUCCESS, payload: response.data})
    
      } catch (error) {
        yield put({type: actions.GET_SCHEDULED_CARRIAGES_FAILURE});
      }
  }
  
function* getSingleCarriage(action) {
    try {
      // const response = yield call(() => getBaseRequest(`agent/get-single-carriage?carriage_id=${action.payload.carriage_id}`)); 
      const user = yield select(getUser);
      const brands = user.brands;
      const brand_id = brands.find(brand => brand.acronym == process.env.brand.acronym).brand_id
      const response = yield call(() => getBaseRequest(`dashboard/jobs/get-single-carriage?carriage_id=${action.payload.carriage_id}&brand_id=${brand_id}`)); 
      yield put({type: actions.GET_SINGLE_CARRIAGE_SUCCESS, payload: response.data})
  
    } catch (error) {
      yield put({type: actions.GET_SINGLE_CARRIAGE_FAILURE});
    }
}

function* getScheduleByWeek(action) {
    try {
      const {date, query} = action.payload;
      const response = yield call(() => getBaseRequest(`agent/get-carriages-by-week?date=${date}&${query}`)); 
      yield put({type: actions.GET_SCHEDULE_BY_WEEK_SUCCESS, payload: response.data})
  
    } catch (error) {
      yield put({type: actions.GET_SCHEDULE_BY_WEEK_FAILURE});
    }
}

function* getQuotesAndOrdersForMap(action) {
    try {
      const {date, query} = action.payload;
      const response = yield call(() => getBaseRequest(`agent/get-quotes-and-orders?${query}`)); 
      yield put({type: actions.GET_QUOTES_AND_ORDERS_FOR_MAP_SUCCESS, payload: response.data})
  
    } catch (error) {
      yield put({type: actions.GET_QUOTES_AND_ORDERS_FOR_MAP_FAILURE});
    }
}


export default function* rootSaga() {
  yield all([
    takeLatest(actions.AGENT_LOGIN_AS_USER, loginAsUser),
    takeLatest(actions.AGENT_ACT_AS_CUSTOMER_ID, actAsCustomerID),
    takeLatest(actions.GET_COUNTRY_LIST, getCountryList),
    takeLatest(actions.GET_SECTORS, getSectors),
    takeLatest(actions.AGENT_UPDATE_QUOTE_DATA, updateQuote),
    takeLatest(actions.GET_SCHEDULED_CARRIAGES, getScheduledCarriages),
    takeLatest(actions.GET_SINGLE_CARRIAGE, getSingleCarriage),
    takeLatest(actions.GET_SCHEDULE_BY_WEEK, getScheduleByWeek),
    takeLatest(actions.GET_QUOTES_AND_ORDERS_FOR_MAP, getQuotesAndOrdersForMap)
  ]);
}
